import React from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { a18, a20, a33, a41, a47, a54, a67 } from "../../../Api/RootApiPath";
import { useState } from "react";
import { InfinitySpin, MagnifyingGlass } from "react-loader-spinner";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { BsHandbag } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import "../../PagesStyles/AdminEcommerce.css";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";

export default function AdminPurchase() {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [labelCode, setLabelCode] = useState("");
  const [barCode, setBarCode] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [allProductTypes, setAllProductTypes] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [allCollectionTypes, setAllCollectionTypes] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [selectedItemCodes, setSelectedItemCodes] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [deleteSelectedButton, setDeleteSelectedButton] = useState(false);
  const [selectBranch, setSelectBranch] = useState("");
  const productsPerPage = 100;

  // newView
  const [active, setActive] = useState("List");
  // newView
  const navigate = useNavigate();

  const fetchCategories = () => {
    // setLoading(true);
    fetch(a18)
      .then((res) => res.json())
      .then((response) => {
        setAllCategories(response.data);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchProductTypes = async () => {
    // setLoading(true);
    await fetch(a20)
      .then((res) => res.json())
      .then((response) => {
        setAllProductTypes(response.data);
      });
  };

  useEffect(() => {
    fetchProductTypes();
  }, []);
  const fetchCollectonData = async () => {
    await fetch(a33)
      .then((res) => res.json())
      .then((response) => {
        setAllCollectionTypes(response.data);
      });
  };

  useEffect(() => {
    fetchCollectonData();
  }, []);

  // console.log("all Products", allProducts);
  //firebase code
  // const firebaseConfig = {
  //   // Your configuration details here
  //   apiKey: "AIzaSyDerNHPJwykR6GA6H_tMb7p-eMdQ8uR13k",
  //   authDomain: "loyalstrings-3c53b.firebaseapp.com",
  //   databaseURL: "https://loyalstrings-3c53b-default-rtdb.firebaseio.com",
  //   projectId: "loyalstrings-3c53b",
  //   storageBucket: "loyalstrings-3c53b.appspot.com",
  //   messagingSenderId: "423371897105",
  //   appId: "1:423371897105:web:5cd4266256c8722a6034b0",
  //   measurementId: "G-GZGQ6XMDSJ",
  // };

  // const app = initializeApp(firebaseConfig);
  // const database = getDatabase(app);
  // const dataref = ref(database, "testdata");

  // const fetchData = () => {
  //   onValue(dataref, (snapshot) => {
  //     const data2 = snapshot.val();
  //     console.log("check allp" + data2);
  //     // setFirebaseData(data2);
  //     setAllProducts([data2]), setLoading(false);
  //   });
  // };
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  const fetchAllProducts = async () => {
    // setLoading(true);
    try {
      const response = await fetch(a54);
      const data = await response.json();
      let branchProducts = data.data;
      console.log(branchProducts, "branchProducts");
      // Add a serial number property to each product
      //   if (selectBranch === "Home" || selectBranch === "Branch 2") {
      //     branchProducts = data.data.filter((x) => x.branchName === selectBranch);
      //   } else {
      //     branchProducts = data.data;
      //   }
      const productsWithSerial = branchProducts.map((product, index) => ({
        ...product,
        serialNumber: index + 1,
      }));

      // setAllProducts(productsWithSerial.reverse()); // Assuming data.data is an array of products
      setAllProducts(productsWithSerial.reverse());
      //   setAllProducts(data.data);
      setDeleteSelected(false);
      console.log(allProducts, "allProducts");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false); // Make sure to set loading to false even on error
    }
  };
  //   const productsData = useSelector((state) => state.reducer7.products);
  // console.log("Fetched Products:", productsData);
  //   useEffect(() => {
  //     setAllProducts(productsData);
  //   }, [allProducts]);
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    fetchAllProducts();
    // fetchData();
  }, []);
  useEffect(() => {
    if (Entryby_Staff_id == 1) {
      setSelectBranch("Home");
    } else if (Entryby_Staff_id == 2) {
      setSelectBranch("Branch2");
    }
  }, []);
  // console.log(allProducts);
  // console.log(allProducts);
  // useEffect(() => {}, [currentPage]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    console.log("nextClick");
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    console.log("prevClick");
  };
  const handleCheckboxChange = (productId) => {
    if (productId === "all") {
      // Select/deselect all checkboxes
      setSelectAll(!selectAll);
      setSelectedProducts(selectAll ? [] : allProducts.map((x) => x.id));
    } else {
      // Check if the product is already selected
      const isSelected = selectedProducts.includes(productId);

      // If the product is already selected, remove it from the list
      if (isSelected) {
        setSelectedProducts((prevSelected) =>
          prevSelected.filter((id) => id !== productId)
        );
      } else {
        // If the product is not selected, add it to the list
        setSelectedProducts((prevSelected) => [...prevSelected, productId]);
      }
    }
  };
  const printAll = () => {
    const selectedProductData = allProducts.filter((x) =>
      selectedProducts.includes(x.id)
    );
    printPDF(selectedProductData);
    console.log("button", selectedProductData);
  };
  // Soni New design
  // const printPDF = async (products) => {
  //   const doc = new jsPDF({
  //     // format: [26, 12],
  //     format: [28, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 8;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       netWt,
  //       stoneAmount,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       description,
  //     } = products[i];

  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }
  //     doc.setFontSize(fontSize);
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 3, 6);
  //       doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 3, 9);
  //       // doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 8);
  //       // doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 20, 8);
  //       doc.text(`Pcs:${pieces}`, 18, 3);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 3, 3);
  //       doc.text(`${purity}`, 22, 6);
  //       doc.setFontSize(5);
  //       // const maxLineLength = 27;
  //       // const descriptionLine1 = description.substring(0, maxLineLength);
  //       // const descriptionLine2 = description.substring(
  //       //   maxLineLength,
  //       //   maxLineLength * 2
  //       // );

  //       // doc.text(descriptionLine1, 4, 10);
  //       // doc.text(descriptionLine2, 4, 11.5);
  //     } else {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 3, 6);
  //       doc.text(`MRP: ${parseFloat(mrp)}`, 3, 9);
  //       doc.text(`Pcs:${pieces}`, 18, 3);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 3, 3);
  //       doc.text(`${purity}`, 18, 6);
  //     }

  //     try {
  //       // const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //       // doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  // Soni label below
  // const printPDF = async (products) => {
  //   const doc = new jsPDF({
  //     // format: [26, 12],
  //     format: [100, 13],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 9;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       netWt,
  //       stoneAmount,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       description,
  //     } = products[i];

  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 4, 6);
  //       doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 4, 9);
  //       doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 12);
  //       doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 30, 12);
  //       doc.text(`Pcs:${pieces}`, 30, 3);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 4, 3);
  //       doc.text(`${purity}`, 30, 6);
  //       // doc.setFontSize(5);
  //       // const maxLineLength = 27;
  //       // const descriptionLine1 = description.substring(0, maxLineLength);
  //       // const descriptionLine2 = description.substring(
  //       //   maxLineLength,
  //       //   maxLineLength * 2
  //       // );

  //       // doc.text(descriptionLine1, 4, 10);
  //       // doc.text(descriptionLine2, 4, 11.5);
  //     } else {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 30, 6);
  //       doc.text(`MRP: ${parseFloat(mrp)}`, 11, 9);
  //       doc.text(`Pcs:${pieces}`, 30, 2);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${product_No}`, 4, 12);
  //       doc.text(`${itemCode}`, 4, 3);
  //       doc.text(`${purity}`, 30, 12);
  //     }

  //     try {
  //       const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //       // doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };
  // Madans label below
  // const printPDF = async (products) => {
  //   const doc = new jsPDF({
  //     // format: [26, 12],
  //     format: [28, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 6;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       netWt,
  //       stoneAmount,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       description,
  //     } = products[i];

  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 4, 4);
  //       doc.text(`N.Wt: ${parseFloat(netWt).toFixed(3)}`, 4, 6);
  //       doc.text(`S.Wt: ${parseFloat(stoneWeight).toFixed(3)}`, 4, 8);
  //       doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 20, 8);
  //       doc.text(`Pcs:${pieces}`, 18, 2);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 4, 2);
  //       doc.text(`${purity}`, 22, 4);
  //       doc.setFontSize(5);
  //       const maxLineLength = 27;
  //       const descriptionLine1 = description.substring(0, maxLineLength);
  //       const descriptionLine2 = description.substring(
  //         maxLineLength,
  //         maxLineLength * 2
  //       );

  //       doc.text(descriptionLine1, 4, 10);
  //       doc.text(descriptionLine2, 4, 11.5);
  //     } else {
  //       doc.text(`G.Wt: ${parseFloat(grosswt).toFixed(3)}`, 11, 5.5);
  //       doc.text(`MRP: ${parseFloat(mrp)}`, 11, 7.5);
  //       doc.text(`Pcs:${pieces}`, 18, 2);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 4, 3);
  //       doc.text(`${purity}`, 18, 11.5);
  //     }

  //     try {
  //       const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //       // doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  // NDDarbar Label below
  const printPDF = async (products) => {
    const doc = new jsPDF({
      format: [29, 12],
      orientation: "landscape",
    });

    const fontSize = 6;
    const imageHeight = 7;
    const imageWidth = 7;

    for (let i = 0; i < products.length; i++) {
      const {
        collection,
        grosswt,
        stoneWeight,
        stoneAmount,
        netWt,
        itemCode,
        purity,
        mrp,
        product_No,
        pieces,
        making_Fixed_Wastage,
        making_Percentage,
      } = products[i];

      // console.log("products", products);
      if (i > 0) {
        doc.addPage(); // Add a new page for each product after the first one
      }

      doc.setFontSize(fontSize);
      doc.setFont("helvetica", "bold");
      // {
      //   collection.length > 20
      //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
      //     : doc.text(`${collection}`, 1, 3);
      // }

      if (mrp == 0 || mrp === "") {
        doc.text(`G.WT: ${parseFloat(grosswt).toFixed(3)}`, 3, 3);
        doc.text(`S.WT: ${parseFloat(stoneWeight).toFixed(3)}`, 3, 5.5);
        if (
          parseFloat(making_Percentage) !== 0 &&
          making_Percentage !== "" &&
          making_Fixed_Wastage !== 0 &&
          making_Fixed_Wastage !== ""
        ) {
          doc.text(
            `W.WT: ${(
              parseFloat(netWt) / parseFloat(making_Percentage) +
              parseFloat(making_Fixed_Wastage)
            ).toFixed(3)}`,
            3,
            7.5
          );
          doc.text(
            `N.WT: ${(
              parseFloat(netWt) +
              parseFloat(netWt / making_Percentage) +
              parseFloat(making_Fixed_Wastage)
            ).toFixed(3)}`,
            3,
            10
          );

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        } else if (
          parseFloat(making_Percentage) !== 0 &&
          making_Percentage !== ""
        ) {
          doc.text(
            `W.WT: ${(
              parseFloat(netWt) / parseFloat(making_Percentage)
            ).toFixed(3)}`,
            3,
            7.5
          );
          doc.text(
            `N.WT: ${(
              parseFloat(netWt) + parseFloat(netWt / making_Percentage)
            ).toFixed(3)}`,
            3,
            10
          );

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        } else if (making_Fixed_Wastage !== 0 && making_Fixed_Wastage !== "") {
          doc.text(
            `W.WT: ${parseFloat(making_Fixed_Wastage).toFixed(3)}`,
            3,
            7.5
          );
          doc.text(
            `N.WT: ${(
              parseFloat(making_Fixed_Wastage) + parseFloat(netWt)
            ).toFixed(3)}`,
            3,
            10
          );

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        } else {
          doc.text(`W.WT: N/A`, 3, 8);
          doc.text(`N.WT: ${netWt.toFixed(3)}`, 3, 10.5);

          doc.text(`${itemCode}`, 18, 3);
          doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
          doc.text(`${purity}`, 18, 7.5);
          doc.text(`Pc:${pieces}`, 18, 10);
        }
      } else {
        doc.text(`G.WT: ${grosswt.toFixed(3)}`, 3, 3);
        doc.text(`MRP: ${parseInt(mrp)}`, 3, 6);
        doc.text(`Pc:${pieces}`, 18, 9);
        // doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 18, 3);
        doc.text(`${purity}`, 18, 6);
      }
    }
    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };

  // Ghares Label Below
  // const printPDF = async (products) => {
  //   const doc = new jsPDF({
  //     format: [29, 12],
  //     orientation: "landscape",
  //   });

  //   const fontSize = 6;
  //   const imageHeight = 7;
  //   const imageWidth = 7;

  //   for (let i = 0; i < products.length; i++) {
  //     const {
  //       collection,
  //       grosswt,
  //       stoneWeight,
  //       stoneAmount,
  //       netWt,
  //       itemCode,
  //       purity,
  //       mrp,
  //       product_No,
  //       pieces,
  //       making_Fixed_Wastage,
  //       making_Percentage,
  //     } = products[i];

  //     // console.log("products", products);
  //     if (i > 0) {
  //       doc.addPage(); // Add a new page for each product after the first one
  //     }

  //     doc.setFontSize(fontSize);
  //     doc.setFont("helvetica", "bold");
  //     // {
  //     //   collection.length > 20
  //     //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
  //     //     : doc.text(`${collection}`, 1, 3);
  //     // }

  //     if (mrp == 0 || mrp === "") {
  //       doc.text(`G.WT: ${parseFloat(grosswt).toFixed(3)}`, 3, 3);
  //       doc.text(`S.WT: ${parseFloat(stoneWeight).toFixed(3)}`, 3, 6);
  //       doc.text(`N.WT: ${parseFloat(netWt).toFixed(3)}`, 3, 9);

  //       doc.text(`${itemCode}`, 18, 3);
  //       // doc.text(`${parseFloat(stoneAmount).toFixed(2)}`, 18, 5.5);
  //       doc.text(`${purity}`, 18, 6);
  //       doc.text(`Pc:${pieces}`, 18, 9);
  //     } else {
  //       doc.text(`G.WT: ${grosswt.toFixed(3)}`, 3, 3);
  //       doc.text(`MRP: ${parseInt(mrp)}`, 3, 6);
  //       doc.text(`Pc:${pieces}`, 18, 9);
  //       // doc.text(`${product_No}`, 4, 11.5);
  //       doc.text(`${itemCode}`, 18, 3);
  ///       doc.text(`${purity}`, 18, 6);
  //     }

  //     // try {
  //     //   const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
  //     //   doc.addImage(qrCodeDataUrl, "JPEG", 1, 3, imageWidth, imageHeight);
  //     // } catch (error) {
  //     //   console.error(error);
  //     // }
  //   }

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };
  const printList = () => {
    const selectedProductData = allProducts.filter((x) =>
      selectedProducts.includes(x.id)
    );
    printListAll(filteredProducts);
  };

  const printListAll = async (data) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const startX = 10; // Adjusted startX value for the serial number column
    let startY = 20;
    const lineHeight = 5;
    const margin = 5;
    const serialNumberWidth = 20; // Width for the serial number column
    const columnWidth =
      (pageWidth - startX - serialNumberWidth - 10 * margin) / 10;

    doc.setFont("helvetica", "normal");
    // doc.setFontSize(12);
    doc.setFontSize(8);

    const generateHeader = () => {
      doc.text("S. No.", startX, startY); // Serial Number
      doc.text("Collection", startX + columnWidth, startY);
      doc.text("Gross Wt", startX + 2 * columnWidth, startY);
      doc.text("Net Wt", startX + 3 * columnWidth, startY);
      doc.text("Item Code", startX + 4 * columnWidth, startY);
      doc.text("Barcode No", startX + 5.5 * columnWidth, startY);
      // doc.text("M Fixed Amt", startX + 7 * columnWidth, startY);
      // doc.text("M Fix Wastage", startX + 8.5 * columnWidth, startY);
      // doc.text("M Percentage", startX + 10 * columnWidth, startY);
      // doc.text("M per_gram", startX + 11.5 * columnWidth, startY);
      // doc.text("stoneAmount", startX + 13 * columnWidth, startY);
      doc.text("Tid", startX + 7.53 * columnWidth, startY);
    };
    const totalNetWt = data.reduce(
      (total, item) => total + (parseFloat(item.netWt) || 0),
      0
    );
    const totalGrossWt = data.reduce(
      (total, item) => total + (parseFloat(item.grosswt) || 0),
      0
    );
    // Generate header on the first page
    generateHeader();
    doc.text(
      `Total Net Wt: ${totalNetWt.toFixed(3)} gm`,
      startX + 5 * columnWidth,
      startY - 10
    );
    doc.text(
      `Total Gross Wt: ${totalGrossWt.toFixed(3)} gm`,
      startX,
      startY - 10
    );
    // Generate data rows

    let y = startY + lineHeight + margin;
    data.forEach((item, index) => {
      // Check if we need to start a new page
      if (index > 0 && y + lineHeight > pageHeight - margin) {
        doc.addPage();
        startY = 20; // Reset startY for the new page
        // Generate header on each new page
        generateHeader();
        y = startY + lineHeight + margin; // Update y position for the new page
      }

      const serialNumber = index + 1;
      doc.text(serialNumber.toString(), startX, y);
      doc.text(
        item.collection ? item.collection.toString().substr(0, 8) : "N/A",
        startX + columnWidth,
        y
      );
      doc.text(
        item.grosswt ? item.grosswt.toString() : "N/A",
        startX + 2 * columnWidth,
        y
      );
      doc.text(
        item.netWt ? item.netWt.toString() : "N/A",
        startX + 3 * columnWidth,
        y
      );
      doc.text(
        item.itemCode ? item.itemCode.toString() : "N/A",
        startX + 4 * columnWidth,
        y
      );
      doc.text(
        item.barcodeNumber ? item.barcodeNumber.toString() : "N/A",
        startX + 5.5 * columnWidth,
        y
      );
      // doc.text(
      //   item.making_Fixed_Amt ? item.making_Fixed_Amt.toString() : "N/A",
      //   startX + 7 * columnWidth,
      //   y
      // );
      // doc.text(
      //   item.making_Fixed_Wastage
      //     ? item.making_Fixed_Wastage.toString()
      //     : "N/A",
      //   startX + 8.5 * columnWidth,
      //   y
      // );
      // doc.text(
      //   item.making_Percentage ? item.making_Percentage.toString() : "N/A",
      //   startX + 10 * columnWidth,
      //   y
      // );
      // doc.text(
      //   item.making_per_gram ? item.making_per_gram.toString() : "N/A",
      //   startX + 11.5 * columnWidth,
      //   y
      // );
      // doc.text(
      //   item.stoneAmount ? item.stoneAmount.toString() : "N/A",
      //   startX + 13 * columnWidth,
      //   y
      // );
      doc.text(
        item.tid ? item.tid.toString() : "N/A",
        startX + 7.5 * columnWidth,
        y
      );
      y += lineHeight + margin;
    });

    // Add page numbers
    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.text(`Page ${i} of ${totalPages}`, pageWidth - 50, pageHeight - 10);
    }

    // Get PDF data as Uint8Array
    const pdfData = doc.output();

    // Create a new Blob from the PDF data
    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    // Create a URL from the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, "_blank");
  };
  const filterOrders = () => {
    let filtered = allProducts;

    if (labelCode !== "") {
      filtered = filtered.filter((product) =>
        product.itemCode.includes(labelCode)
      );
    }
    if (barCode !== "") {
      filtered = filtered.filter(
        (product) =>
          product.barcodeNumber && product.barcodeNumber.includes(barCode)
      );
    }

    if (categoryName == "") {
      // setCategoryName(""),
      setProductName(""),
        setCollectionName(""),
        setFilteredProducts(allProducts);
      // console.log("categoryName", categoryName);
    }
    // if (productName == "") {
    //   setCollectionName("");
    //   filtered = filtered.filter(
    //     (product) =>
    //       product.category_Name &&
    //       product.category_Name.includes(categoryNameSelected)
    //   );
    //   setFilteredProducts(filtered);
    // }
    // if (collectionName == "") {
    //   filtered = filtered.filter(
    //     (product) =>
    //       product.itemType && product.itemType.includes(productNameSelected)
    //   );
    //   setFilteredProducts(filtered);
    // }
    if (categoryName !== "") {
      filtered = filtered.filter(
        (product) =>
          product.categoryName &&
          product.categoryName.includes(categoryNameSelected)
      );
    }
    if (productName !== "") {
      console.log("filteredProductsTypes1", filtered);
      filtered = filtered.filter(
        (product) =>
          // product.itemType && product.itemType.includes(productNameSelected)
          product.productname && product.productname === productNameSelected
      );
      console.log("filteredProductsTypes2", filtered);
    }
    if (collectionName !== "") {
      filtered = filtered.filter(
        (product) =>
          product.collection &&
          product.collection.includes(collectionNameSelected)
      );
    }

    if (fromDate !== "" && toDate !== "") {
      filtered = filtered.filter((product) => {
        const createdDate = new Date(product.createdOn);
        return (
          createdDate >= new Date(fromDate) && createdDate <= new Date(toDate)
        );
      });
    }

    setFilteredProducts(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };
  console.log("Filtered", filteredProducts);
  useEffect(() => {
    filterOrders();
    window.scrollTo(0, 0);
    // console.log("filtered", filteredProducts);
    // console.log("ptype", allProductTypes);
  }, [
    categoryName,
    productName,
    collectionName,
    labelCode,
    barCode,
    allProducts,
    fromDate,
    toDate,
  ]);
  let categoryId = parseInt(categoryName.split(",")[0]);
  let categoryNameSelected = categoryName.split(",")[1];
  const filteredProductTypes = allProductTypes.filter(
    (product) => product.category_id == categoryId
  );
  let productNameSelected = productName.split(",")[1];
  let productTypeIdSelected = parseInt(productName.split(",")[0]);
  let collectionNameSelected = collectionName.split(",")[1];
  const filteredCollection = allCollectionTypes.filter(
    (product) => product.productType == productNameSelected
  );
  console.log("current", currentProducts);

  // const filteredCollection = collectionTypeData.filter(
  //   (product) => product.productType == productTypeName
  // );
  // console.log(filteredProducts);
  const handleDeleteCheckboxChange = (productId, itemCode) => {
    let updatedCheckedProducts = [...checkedProducts];
    let updatedSelectedItemCodes = [...selectedItemCodes];

    if (updatedCheckedProducts.includes(productId)) {
      updatedCheckedProducts = updatedCheckedProducts.filter(
        (id) => id !== productId
      );
      updatedSelectedItemCodes = updatedSelectedItemCodes.filter(
        (code) => code !== itemCode
      );
    } else {
      updatedCheckedProducts.push(productId);
      updatedSelectedItemCodes.push(itemCode);
    }

    if (updatedCheckedProducts.length > 0) {
      setDeleteSelectedButton(true);
    } else {
      setDeleteSelectedButton(false);
    }

    setCheckedProducts(updatedCheckedProducts);
    setSelectedItemCodes(updatedSelectedItemCodes);
  };
  const selectedItems = selectedItemCodes.map((itemCode) => ({
    ItemCode: itemCode,
  }));
  const deleteAllProducts = async (itemsToDelete) => {
    try {
      const response = await fetch(a47, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(itemsToDelete),
      });

      const rcvdData = await response.json();
      console.log("AllItemsDeleted", rcvdData);
      console.log("Responsee:", rcvdData.message);
      if (rcvdData.status == "Success") {
        // Deletion was successful
        // console.log("Item deleted successfully:", response.message);
        alert(rcvdData.message);
        setSelectedItemCodes([]);
        setCheckedProducts([]);
        fetchAllProducts();
        // You can show an alert or notification here
        // alert(data.message);

        setDeleteSelectedButton(false);
      } else {
        // Handle the case where deletion failed
        console.error("Failed to delete item:", response.message);

        // You can show an error message to the user
        alert("Failed to delete item: " + response.message);
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);

      // Show an error message to the user
      // alert("An error occurred while deleting the item.");
    }
  };

  // RD Purchase List
  const [allOrders2, setAllOrders2] = useState([]);
  const [loading2, setLoading2] = useState(true);
  const [orderStatus2, setOrderStatus2] = useState("");
  const [orderNumber2, setOrderNumber2] = useState("");
  const [fromDate2, setFromDate2] = useState("");
  const [toDate2, setToDate2] = useState("");
  const [filteredOrders2, setFilteredOrders2] = useState([]);
  const [orderItems2, setOrderItems2] = useState([]);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [csData2, setCsData2] = useState([]);
  const [allPayments2, setAllPayments2] = useState([]);
  const ordersPerPage2 = 25;
  useEffect(() => {
    fetch(a67)
      .then((res) => res.json())
      .then((response) => {
        let rcvdData = response.data.reverse();
        setLoading2(false);

        let orderedData = rcvdData.map((item, index) => {
          // Add extra property 'orderStatus'
          item.orderStatus =
            Math.ceil(item.balanceAmount) == 0 &&
            item.balanceGold == "0.000" &&
            item.balanceSilver == "0.000"
              ? "Paid"
              : Math.ceil(item.balanceAmount) == 0 ||
                item.balanceGold == "0.000" ||
                item.balanceSilver == "0.000"
              ? "Partial"
              : "None";
          // Add 's.no' property
          item.serialNumber = index + 1;
          return item;
        });

        setAllOrders2(orderedData);
        console.log(response.data, "response.data for 2");
      });
  }, []);

  // useEffect(() => {
  //   fetch(a73)
  //     .then((res) => res.json())
  //     .then((response) => {
  //       // console.log(response);
  //       let rcvdData = response.data.reverse();
  //       let supplierOrders = rcvdData.filter((x) => x.customerId == 0);
  //       setAllOrders2(...allOrders2, supplierOrders);
  //       setLoading2(false);

  //       // setOlddata(response);
  //       console.log(response.data);
  //     });
  // }, []);

  const filterOrders2 = () => {
    let filtered2 = allOrders2;

    if (orderStatus2 && orderStatus2 !== "") {
      filtered2 = filtered2.filter(
        (order) => order.orderStatus === orderStatus2
      );
    }

    if (orderNumber2 && orderNumber2 !== "") {
      const lowercaseOrderNumber2 = orderNumber2.toLowerCase();
      filtered2 = filtered2.filter((order) => {
        const customer = order.party_Details;
        const invoiceNoMatch2 =
          order.invoiceNo &&
          order.invoiceNo.toLowerCase().includes(lowercaseOrderNumber2);
        const firstNameMatch2 =
          customer.firm_name &&
          customer.firm_name.toLowerCase().includes(lowercaseOrderNumber2);
        const lastNameMatch2 =
          customer.supplier_name &&
          customer.supplier_name.toLowerCase().includes(lowercaseOrderNumber2);
        const currAddStreetMatch2 =
          customer.currAddStreet &&
          customer.currAddStreet.toLowerCase().includes(lowercaseOrderNumber2);
        const currAddTownMatch2 =
          customer.currAddTown &&
          customer.currAddTown.toLowerCase().includes(lowercaseOrderNumber2);
        const currAddStateMatch2 =
          customer.currAddState &&
          customer.currAddState.toLowerCase().includes(lowercaseOrderNumber2);
        const currAddPincodeMatch2 =
          customer.currAddPincode &&
          customer.currAddPincode.toLowerCase().includes(lowercaseOrderNumber2);
        const perAddStreetMatch2 =
          customer.perAddStreet &&
          customer.perAddStreet.toLowerCase().includes(lowercaseOrderNumber2);
        const perAddTownMatch2 =
          customer.perAddTown &&
          customer.perAddTown.toLowerCase().includes(lowercaseOrderNumber2);
        const perAddStateMatch2 =
          customer.perAddState &&
          customer.perAddState.toLowerCase().includes(lowercaseOrderNumber2);
        const perAddPincodeMatch2 =
          customer.perAddPincode &&
          customer.perAddPincode.toLowerCase().includes(lowercaseOrderNumber2);
        const csMobileMatch2 =
          customer.mobile &&
          customer.mobile.toLowerCase().includes(lowercaseOrderNumber2);
        const rcvdAmtMatch2 =
          order.receivedAmt &&
          order.receivedAmt.toString().includes(lowercaseOrderNumber2);
        const priceAmtMatch2 =
          order.price && order.price.toString().includes(lowercaseOrderNumber2);

        return (
          invoiceNoMatch2 ||
          firstNameMatch2 ||
          lastNameMatch2 ||
          currAddStreetMatch2 ||
          currAddTownMatch2 ||
          currAddStateMatch2 ||
          currAddPincodeMatch2 ||
          perAddStreetMatch2 ||
          perAddTownMatch2 ||
          perAddStateMatch2 ||
          perAddPincodeMatch2 ||
          csMobileMatch2 ||
          rcvdAmtMatch2 ||
          priceAmtMatch2
        );
      });
    }

    if (fromDate2 !== "" && toDate2 !== "") {
      filtered2 = filtered2.filter((order) => {
        const orderDate2 = new Date(order.createdOn);
        return (
          orderDate2 >= new Date(fromDate2) && orderDate2 <= new Date(toDate2)
        );
      });
    }

    setFilteredOrders2(filtered2);
    setCurrentPage2(1); // Reset to the first page after filtering
  };
  console.log(allOrders2, "allOrders2");
  useEffect(() => {
    filterOrders2();
    window.scrollTo(0, 0);

    console.log(filteredOrders2);
  }, [orderStatus2, orderNumber2, allOrders2, fromDate2, toDate2]);

  // useEffect(() => {}, [currentPage]);
  // console.log(JSON.stringify(orderItems), "orderItems");
  console.log(allOrders2);
  const indexOfLastProduct2 = currentPage2 * ordersPerPage2;
  const indexOfFirstProduct2 = indexOfLastProduct2 - ordersPerPage2;
  // const currentOrders2 = filteredOrders2.slice(
  //   indexOfFirstProduct2,
  //   indexOfLastProduct2
  // );
  const currentOrders2 = Array.isArray(filteredOrders2)
    ? filteredOrders2.slice(indexOfFirstProduct2, indexOfLastProduct2)
    : [];
  const totalPages2 = Math.ceil(filteredOrders2.length / ordersPerPage2);

  const goToNextPage2 = () => {
    setCurrentPage2((prevPage2) => prevPage2 + 1);
  };

  const goToPreviousPage2 = () => {
    setCurrentPage2((prevPage2) => prevPage2 - 1);
  };
  // RD Purchase List
  return (
    <div>
      <AdminHeading />
      <div style={{ paddingTop: "130px" }}>
        <AdminBreadCrump
          title={"Purchase"}
          companyName={"Loyalstring"}
          module={"Trading"}
          page={"Purchase"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 01 */}
                  <RiListUnordered />
                </div>
                <p>URD Purchase</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 02 */}
                  <RiPlayListAddLine />
                </div>
                <p>RD Purchase</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <div className={loading == true ? "loading" : "none"}>
                <InfinitySpin width="200" color="#4fa94d" />
              </div>

              <div className={loading == true ? "loading" : "none"}>
                {/* <h1>Loading...</h1> */}
                {/* <InfinitySpin width="200" color="#4fa94d" /> */}
                <MagnifyingGlass
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="MagnifyingGlass-loading"
                  wrapperStyle={{}}
                  wrapperClass="MagnifyingGlass-wrapper"
                  glassColor="#c0efff"
                  color="#e15b64"
                />
              </div>

              {!loading == true ? (
                <div>
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "left",
                      flexWrap: "wrap",
                    }}
                    className="adminAllProductsFilterBox"
                  >
                    <div className="adminAllProductsFilterCategoryBox">
                      <select
                        value={categoryName}
                        onChange={(e) => {
                          setCategoryName(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value="">Select Category</option>
                        {allCategories.map((x) => {
                          return (
                            <option value={`${x.id},${x.name}`}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        value={productName}
                        onChange={(e) => {
                          setProductName(e.target.value);
                          setCurrentPage(1);
                          setCollectionName("");
                        }}
                      >
                        <option value="">Select Product Type</option>
                        {filteredProductTypes.map((x) => {
                          return (
                            <option
                              value={`${parseInt(x.id)},${x.productTitle}`}
                            >
                              {x.productTitle}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminAllProductsFilterDatesBox">
                      {/* <div
                        style={{
                          display: "flex",
                          marginTop: "1rem",
                          alignItems: "center",
                        }}
                      > */}
                      <input
                        type="date"
                        placeholder="From Date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />

                      <input
                        // style={{ margin: "1rem" }}
                        type="date"
                        placeholder="To Date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />

                      {/* <AiOutlineEdit
                      className="labelledListEditIcon"
                      style={{ padding: "5px" }}
                      size={"1.5rem"}
                      onClick={() => {
                        setDeleteSelected(!deleteSelected),
                          setSelectedItemCodes([]),
                          setCheckedProducts([]),
                          setDeleteSelectedButton(false);
                      }}
                    /> */}
                    </div>
                    <div className="adminAllLabelledListButtonBox">
                      <button onClick={printAll}>Print</button>
                      <button onClick={printList}>Print List</button>

                      <button
                        onClick={() => {
                          setSelectedProducts([]),
                            setSelectAll(false),
                            setCategoryName(""),
                            setProductName(""),
                            setCollectionName(""),
                            setFromDate(""),
                            setToDate(""),
                            setFilteredProducts(allProducts);
                        }}
                      >
                        Reset
                      </button>
                      {deleteSelectedButton ? (
                        <button
                          onClick={() => deleteAllProducts(selectedItems)}
                          style={{ backgroundColor: "#c14456", color: "white" }}
                        >
                          Delete Selected
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ overflowX: "auto" }}>
                    <table
                      className="adminInventoryMainTable"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        marginLeft: "1rem",
                        boxSizing: "border-box",
                      }}
                    >
                      <thead>
                        <tr>
                          {deleteSelected ? <th>Delete Item</th> : null}
                          <th>S.No</th>
                          {/* <th>Collection Name</th> */}
                          <th>Category</th>
                          <th>Product</th>
                          <th>Gross Wt</th>
                          <th>Stone WT</th>
                          <th>Net Wt</th>
                          <th>FinePercent</th>
                          <th>Gold Rate</th>
                          <th>Purchase amount</th>
                          <th>Purchased On</th>
                          {/* <th>Item Code</th> */}
                          {/* <th>Barcode Number</th> */}
                          {/* <th>Branch</th> */}
                          {/* <th>Tid</th> */}
                          {/* <th>Tid</th> */}
                          {/* <th>Select</th> */}
                        </tr>
                      </thead>
                      <tbody style={{ position: "relative" }}>
                        {currentProducts.map((x, index) => (
                          <tr key={x.id}>
                            {/* <td>{index}</td> */}
                            {deleteSelected ? (
                              <td>
                                <input
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                  type="checkbox"
                                  checked={checkedProducts.includes(x.id)}
                                  onChange={() =>
                                    handleDeleteCheckboxChange(x.id, x.itemCode)
                                  }
                                />
                              </td>
                            ) : null}
                            <td>{x.serialNumber}</td>
                            {/* <td>{x.product_Name}</td> */}
                            {/* <td
                            onClick={() => {
                              navigate(`/product_details?productId=${x.id}`);
                            }}
                            className="adminAllProductsTitle"
                          >
                            {x.collection}
                          </td> */}
                            <td>{x.categoryName}</td>
                            <td>{x.productname}</td>
                            <td>{parseFloat(x.grosswt).toFixed(3)}</td>
                            <td>{parseFloat(x.stonewt).toFixed(3)}</td>
                            <td>{parseFloat(x.netwt).toFixed(3)}</td>
                            <td>{x.finepercent}</td>
                            <td>{x.goldRate}</td>
                            <td>{x.purchaseAmount}</td>
                            <td>
                              {new Date(x.createdOn).toLocaleDateString()}
                            </td>
                            {/* <td>{x.stoneWeight}</td>
                    <td>{x.netWt}</td> */}
                            {/* <td>₹ {x.mrp}</td>
                          <td>{x.itemCode}</td>
                          <td>{x.barcodeNumber}</td>
                          <td>{x.branchName}</td>
                          <td>{x.tid}</td>
                          <td>
                            <input
                              style={{
                                cursor: "pointer",
                              }}
                              onChange={() => handleCheckboxChange(x.id)}
                              type="checkbox"
                              checked={
                                selectAll || selectedProducts.includes(x.id)
                              }
                            />
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                      {/* <div className="adminAllLabelledListButtonBox">
                        <button onClick={printAll}>Print</button>
                        <button onClick={printList}>Print List</button>
  
                        <button
                          onClick={() => {
                            setSelectedProducts([]),
                              setSelectAll(false),
                              setCategoryName(""),
                              setProductName(""),
                              setCollectionName(""),
                              setFromDate(""),
                              setToDate(""),
                              setFilteredProducts(allProducts);
                          }}
                        >
                          Reset
                        </button>
                        {deleteSelectedButton ? (
                          <button
                            onClick={() => deleteAllProducts(selectedItems)}
                            style={{ backgroundColor: "#c14456", color: "white" }}
                          >
                            Delete Selected
                          </button>
                        ) : null}
                      </div> */}
                    </table>
                  </div>
                </div>
              ) : null}

              {/* Pagination controls */}
              <div className="bulkProductAddingTableMain">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                  Previous
                </button>
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <div className={loading2 == true ? "loading" : "none"}>
                <InfinitySpin width="200" color="#4fa94d" />
              </div>

              <div
                style={{
                  width: "100%",
                  justifyContent: "left",
                  flexWrap: "wrap",
                  marginBottom: "30px",
                }}
                className="adminAllProductsFilterBox"
              >
                <div
                  style={{ marginBottom: "5px" }}
                  className="adminAllProductsFilterCategoryBox"
                >
                  <select
                    value={orderStatus2}
                    onChange={(e) => {
                      setOrderStatus2(e.target.value), setCurrentPage2(1);
                    }}
                  >
                    <option value="">Choose...</option>
                    <option value="Paid">Paid</option>
                    <option value="Partial">Partial</option>
                    <option value="None">None</option>
                  </select>
                </div>
                <div className="adminAllProductsFilterLabelBox">
                  <input
                    type="date"
                    placeholder="From Date"
                    value={fromDate2}
                    onChange={(e) => setFromDate2(e.target.value)}
                  />
                  <input
                    type="date"
                    placeholder="To Date"
                    value={toDate2}
                    onChange={(e) => setToDate2(e.target.value)}
                  />
                </div>
                <div className="adminAllProductsFilterLabelBox">
                  <input
                    type="text"
                    placeholder="Search Name / Address / Mobile / Amount / Invoice..."
                    value={orderNumber2}
                    onChange={(e) => {
                      setOrderNumber2(e.target.value.toLowerCase()),
                        setCurrentPage2(1);
                    }}
                  />
                </div>
              </div>
              <div
                className="adminAllOrdersTableMainBox"
                style={{ overflow: "auto" }}
              >
                <table
                  className="adminInventoryMainTable"
                  style={{
                    width: "100%",
                    marginLeft: "1rem",
                    boxSizing: "border-box",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Inv No</th>
                      <th>Inward No</th>
                      {/* <th>Customer Id</th> */}
                      <th>Supplier Name</th>
                      <th>Purchase Amt</th>
                      <th>Purchase Gold</th>
                      <th>Purchase Silver</th>
                      {/* <th>Balance Amt</th> */}
                      {/* <th>Product Id</th> */}
                      {/* <th>Product Name</th> */}
                      {/* <th>Item Code</th> */}
                      {/* <th>Quantity</th> */}
                      <th>Balance Amt</th>
                      <th>Balance Gold</th>
                      <th>Balance Silver</th>
                      <th>Branch</th>
                      <th>Discount</th>
                      <th>Created On</th>
                      <th>Order Status</th>
                      <th>Show Bill</th>
                      {/* <th>Payment Mode</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentOrders2.map((x) => {
                      return (
                        // <tr key={x.Customer_id}>
                        <tr
                          // onClick={() =>
                          //   navigate(
                          //     `/supplier_allpayments?supplierId=${x.party_Details.id}&&invoiceFor=${x.id}`
                          //   )
                          // }
                          className="adminLedgerMainSupplierRow"
                          style={{ whiteSpace: "nowrap" }}
                          key={x.id}
                        >
                          <td>{x.id}</td>
                          <td
                            // onClick={() => {
                            //   navigate(`/admin-orderdetails/${x.id}`);
                            // }}
                            onClick={() => {
                              // navigate(
                              //   `/admin_invoice_edit?objectRcvd=${JSON.stringify(
                              //     x
                              //   )}`
                              // );
                              getAllOrderItemsForSending(x.id, x);
                            }}
                            className="adminAllOrdersOrderData"
                          >
                            {x.invoiceNo}
                          </td>
                          {/* <td>{x.customer_Id}</td> */}
                          {/* NOTE:"Please Uncomment bekow line" */}

                          <td style={{ fontWeight: "bold" }}>{x.inwardNo}</td>
                          <td>{x.party_Details.supplier_name}</td>
                          <td>
                            ₹
                            {parseInt(x.purchaseAmount).toLocaleString("en-IN")}
                          </td>
                          <td>{x.fineGold}</td>
                          <td>{x.fineSilver}</td>

                          <td
                            style={{
                              color:
                                Math.floor(x.balanceAmount) === 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            ₹{parseInt(x.balanceAmount).toLocaleString("en-IN")}
                          </td>
                          {/* <td>{x.balanceAmount}</td> */}
                          <td
                            style={{
                              color:
                                x.balanceGold === "0.000" ? "green" : "red",
                            }}
                          >
                            {x.balanceGold}
                          </td>
                          <td
                            style={{
                              color:
                                x.balanceSilver === "0.000" ? "green" : "red",
                            }}
                          >
                            {x.balanceSilver}
                          </td>
                          {/* <td>{x.product_id}</td> */}
                          {/* <td>{x.tblProduct.product_Name}</td> */}
                          {/* <td
                          className="adminAllOrdersOrderData"
                          onClick={() => {
                            navigate(`/admin-orderdetails/${x.id}`);
                          }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {x.tblProduct.itemCode}
                        </td> */}

                          {/* <td>{x.qty}</td> */}
                          {/* <td>
                            ₹{parseInt(x.receivedAmt).toLocaleString("en-IN")}
                          </td> */}
                          <td>{x.branch}</td>
                          <td>₹{x.discount}</td>
                          <td>{new Date(x.createdOn).toLocaleDateString()}</td>
                          <td
                            style={{
                              whiteSpace: "nowrap",
                              color:
                                x.orderStatus === "Paid"
                                  ? "green"
                                  : x.orderStatus == "Partial"
                                  ? "orange"
                                  : "red",
                            }}
                          >
                            {x.orderStatus}
                          </td>
                          {/* <td>{x.orderStatus.filter((y) => y !== "Pending")}</td> */}
                          {/* <td>{x.paymentMode}</td> */}
                          {/* <td>
                          {x.orderStatus === "Paid" ? (
                            <select
                              required="required"
                              value={orderStatus}
                              onChange={(e) =>
                                handleOrderStatusChange(
                                  x.orderNumber,
                                  e.target.value
                                )
                              }
                            >
                              <option value={x.orderStatus}>Change..</option>
                              
                              <option value="Shipped">Shipped</option>
                              <option value="Delivered">Delivered</option>
                            </select>
                          ) : x.orderStatus === "Shipped" ? (
                            <select
                              required="required"
                              value={orderStatus}
                              onChange={(e) =>
                                handleOrderStatusChange(
                                  x.orderNumber,
                                  e.target.value
                                )
                              }
                            >
                              <option value={x.orderStatus}>Change..</option>
                              <option value="Delivered">Delivered</option>
                            </select>
                          ) : (
                            <p
                              style={
                                x.orderStatus === "Paid"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgba(0, 128, 0, 0.7)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Processing Payment"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(219, 153, 30)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Payment Failed"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(231, 30, 60)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Shipped"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(77, 155, 228)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Delivered"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(159, 77, 206)",
                                      whiteSpace: "nowrap",
                                    }
                                  : {
                                      fontWeight: "bold",
                                      color: "rgb(180, 180, 46)",
                                      whiteSpace: "nowrap",
                                    }
                              }
                            >
                              {x.orderStatus}
                            </p>
                          )}
                        </td> */}
                          <td>
                            <button
                              style={{ padding: "0px", cursor: "pointer" }}
                              onClick={() => {
                                // showPDFWithId(x.id), setLoading(true);
                                // generateBillPDF([x], [x]);
                                // console.log("order", [x.tblProduct]);
                                //   setCsData(x);
                                //   getAllOrderItems(x.id);
                              }}
                            >
                              BILL
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="bulkProductAddingTableMain">
                  <button
                    onClick={goToPreviousPage2}
                    disabled={currentPage2 === 1}
                  >
                    Previous
                  </button>
                  <button
                    onClick={goToNextPage2}
                    disabled={currentPage2 === totalPages2}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={active === "List" ? "adminCategoryListMainBox" : "none"}
        >
          <div className="adminInventoryMobilePrintButtonsBox">
            <button onClick={printAll}>Print</button>
            <button onClick={printList}>Print List</button>

            <button
              onClick={() => {
                setSelectedProducts([]),
                  setSelectAll(false),
                  setCategoryName(""),
                  setProductName(""),
                  setCollectionName(""),
                  setFromDate(""),
                  setToDate(""),
                  setFilteredProducts(allProducts);
              }}
            >
              Reset
            </button>
            {deleteSelectedButton ? (
              <button
                onClick={() => deleteAllProducts(selectedItems)}
                style={{ backgroundColor: "#c14456", color: "white" }}
              >
                Delete Selected
              </button>
            ) : null}
          </div>
        </div>

        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
