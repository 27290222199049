import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import {
  a18,
  a20,
  a22,
  a28,
  a30,
  a33,
  a35,
  a68,
  a7,
  a71,
  a72,
} from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";
import { FaImages } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import AlertMessage from "../../../Other Functions/AlertMessage";

export default function AdminAddSku() {
  const [active, setActive] = useState("List");
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageToShow, setMessageToShow] = useState("");

  const [allSku, setAllSku] = useState([]);
  const [newSku, setNewSku] = useState({
    stockKeepingUnit: "",
    description: "",
    productRemark: "",
    productType: "",
    purity: "",
    colour: "",
    category: "",
    collection: "",
    size: "",
    grossWt: "",
    netWt: "",
    totalStoneWt: "",
    images: "",
    stoneAmount1: "",
    stoneAmount2: "",
    stoneAmount3: "",
    stoneAmount4: "",
    stoneName1: "",
    stoneName2: "",
    stoneName3: "",
    stoneName4: "",
    stoneWt1: "",
    stoneWt2: "",
    stoneWt3: "",
    stoneWt4: "",
    makingPercentage: "",
    makingFixedAmt: "",
    makingFixedWastage: "",
    makingPerGram: "",
    mrp: "",
    saleType: "",
    stonePieces: "",
    purityId: 0,
    productTypeId: 0,
    collectionId: 0,
    categoryId: 0,
  });
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const fetchAllSku = async () => {
    try {
      const response = await fetch(a71);
      const data = await response.json();
      console.log(data);
      setAllSku(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllSku();
  }, []);
  console.log(allSku);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});

  const handleEditClick = (id) => {
    setEditingId(id);
    // Find the item with the selected ID and set its data in the state
    const selectedItem = allSku.find((x) => x.id === id);
    setEditedData(selectedItem);
  };

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    try {
      const response = await fetch(a35, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedData),
      });
      const data = await response.json();
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
      } else {
        setMessageType("success");
        setMessageToShow("Updated successfully");
        setShowError(true);
      }
      console.log(data, "updated");
      fetchAllSku();
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };

  const handleNewSkuChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewSku({ ...newSku, [name]: value });
  };
  const addNewSku = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append regular fields
    formData.append("StockKeepingUnit", stockKeepingUnit.toUpperCase());
    formData.append("Description", description);
    formData.append("ProductRemark", productRemark);
    formData.append("ProductType", productTypeName);
    formData.append("Purity", purityName);
    formData.append("Colour", color);
    formData.append("Collection", collectionName);
    formData.append("CategoryId", parseInt(categoryId)); // Convert to string
    formData.append("Category", categoryName.toString()); // Convert to string
    formData.append("Size", size);
    formData.append("GrossWt", grosswt.toString()); // Convert to string
    formData.append("NetWt", netWt.toString()); // Convert to string
    formData.append("TotalStoneWt", stoneWeight.toString()); // Convert to string

    // Append StoneAmounts, StoneNames, StoneWts
    formData.append("StoneAmount1", stoneAmount1.toString()); // Convert to string
    formData.append("StoneAmount2", stoneAmount2.toString()); // Convert to string
    formData.append("StoneAmount3", stoneAmount3.toString()); // Convert to string
    formData.append("StoneAmount4", stoneAmount4.toString()); // Convert to string
    formData.append("StoneName1", stoneName1);
    formData.append("StoneName2", stoneName2);
    formData.append("StoneName3", stoneName3);
    formData.append("StoneName4", stoneName4);
    formData.append("StoneWt1", stoneWt1.toString()); // Convert to string
    formData.append("StoneWt2", stoneWt2.toString()); // Convert to string
    formData.append("StoneWt3", stoneWt3.toString()); // Convert to string
    formData.append("StoneWt4", stoneWt4.toString()); // Convert to string

    // Append other fields
    formData.append("MakingPercentage", making_Percentage.toString()); // Convert to string
    formData.append("MakingFixedAmt", making_Fixed_Amt.toString()); // Convert to string
    formData.append("MakingFixedWastage", making_Fixed_Wastage.toString()); // Convert to string
    formData.append("MakingPerGram", making_per_gram.toString()); // Convert to string
    formData.append("MRP", mrp.toString()); // Convert to string
    formData.append("SaleType", saleType);
    formData.append("StonePieces", stonePieces.toString()); // Convert to string
    formData.append("PurityId", parseInt(purityId));
    formData.append("ProductTypeId", parseInt(productTypeId));
    formData.append("CollectionId", parseInt(collectionId)); // Convert to string

    // Images
    selectedFiles.forEach((file) => {
      formData.append("Images", file);
    });
    // Now you can use this formData object in your fetch request
    console.log(formData, "formData");
    for (const entry of formData.entries()) {
      console.log(entry);
    }
    try {
      const response = await fetch(a72, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      fetchAllSku();
      setActive("List");
      setNewSku({
        stockKeepingUnit: "",
        description: "",
        productRemark: "",
        productType: "",
        purity: "",
        colour: "",
        category: "",
        collection: "",
        size: "",
        grossWt: "",
        netWt: "",
        totalStoneWt: "",
        images: "",
        stoneAmount1: "",
        stoneAmount2: "",
        stoneAmount3: "",
        stoneAmount4: "",
        stoneName1: "",
        stoneName2: "",
        stoneName3: "",
        stoneName4: "",
        stoneWt1: "",
        stoneWt2: "",
        stoneWt3: "",
        stoneWt4: "",
        makingPercentage: "",
        makingFixedAmt: "",
        makingFixedWastage: "",
        makingPerGram: "",
        mrp: "",
        saleType: "",
        stonePieces: "",
        purityId: 0,
        productTypeId: 0,
        collectionId: 0,
        categoryId: 0,
      });
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
        setActive("AddNew");
      } else {
        setMessageType("success");
        setMessageToShow("SKU Added Successfully");
        setShowError(true);
      }
      resetStateValues();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  const resetStateValues = () => {
    setPartyTypeId("");
    setCategory("");
    setProductType("");
    setCollection("");
    setPurity("");
    setProductTypeData([]);
    setCategoriesData([]);
    setPurityData([]);
    setPartyData([]);
    setBoxData([]);
    setCollectionTypeData([]);

    setStockKeepingUnit("");
    setProductRemark("");
    setGrosswt(0);
    setNetWt(0);
    setStoneWeight(0);
    setSelectedFiles([]);
    setSize("");
    setDescription("");
    setMaking_per_gram(0);
    setMaking_Fixed_Amt(0);
    setMaking_Percentage(0);
    setMaking_Fixed_Wastage(0);
    setMRP(0);
    setColor("");
    setStoneAmount1(0);
    setStoneAmount2(0);
    setStoneAmount3(0);
    setStoneAmount4(0);
    setStoneName1("");
    setStoneName2("");
    setStoneName3("");
    setStoneName4("");
    setStoneWt1(0);
    setStoneWt2(0);
    setStoneWt3(0);
    setStoneWt4(0);
    setSaleType("MRP");
    setStonePieces(0);
    setAllStones([]);
  };
  //   Add bulk stock
  const [partyTypeId, setPartyTypeId] = useState("");
  const [category, setCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [collection, setCollection] = useState("");
  const [purity, setPurity] = useState("");
  const [productTypeData, setProductTypeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [purityData, setPurityData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [collectionTypeData, setCollectionTypeData] = useState([]);

  const [stockKeepingUnit, setStockKeepingUnit] = useState("");
  const [productRemark, setProductRemark] = useState("");
  const [grosswt, setGrosswt] = useState(0);
  const [netWt, setNetWt] = useState(0);
  const [stoneWeight, setStoneWeight] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [size, setSize] = useState("");
  const [description, setDescription] = useState("");
  const [making_per_gram, setMaking_per_gram] = useState(0);
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState(0);
  const [making_Percentage, setMaking_Percentage] = useState(0);
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState(0);
  const [mrp, setMRP] = useState(0);
  const [color, setColor] = useState("");
  const [stoneAmount1, setStoneAmount1] = useState(0);
  const [stoneAmount2, setStoneAmount2] = useState(0);
  const [stoneAmount3, setStoneAmount3] = useState(0);
  const [stoneAmount4, setStoneAmount4] = useState(0);
  const [stoneName1, setStoneName1] = useState("");
  const [stoneName2, setStoneName2] = useState("");
  const [stoneName3, setStoneName3] = useState("");
  const [stoneName4, setStoneName4] = useState("");
  const [stoneWt1, setStoneWt1] = useState(0);
  const [stoneWt2, setStoneWt2] = useState(0);
  const [stoneWt3, setStoneWt3] = useState(0);
  const [stoneWt4, setStoneWt4] = useState(0);
  const [saleType, setSaleType] = useState("MRP");
  const [stonePieces, setStonePieces] = useState(0);
  const [allStones, setAllStones] = useState([]);

  useEffect(() => {
    fetch(a18)
      .then((res) => res.json())
      .then((data) => setCategoriesData(data.data));
  }, []);
  useEffect(() => {
    fetch(a20)
      .then((x) => x.json())
      .then((y) => setProductTypeData(y.data));
  }, []);
  // console.log(productTypeData.category_id);

  useEffect(() => {
    fetch(a22)
      .then((res) => res.json())
      .then((data) => setPurityData(data.data));
    // console.log(purityData);
  }, []);
  useEffect(() => {
    fetch(a28)
      .then((res) => res.json())
      .then((data) => setPartyData(data.data.result));
  }, []);
  useEffect(() => {
    fetch(a33)
      .then((res) => res.json())
      .then((data) => setCollectionTypeData(data.data));
  }, []);
  // console.log(partyData);
  useEffect(() => {
    fetch(a30)
      .then((res) => res.json())
      .then((data) => setBoxData(data.data));
  }, []);
  useEffect(() => {
    fetch(a68)
      .then((res) => res.json())
      .then((data) => setAllStones(data.data));
  }, []);

  let categoryId = parseInt(category.split(",")[0]);
  let categoryName = category.split(",")[1];
  let productTypeId = parseInt(productType.split(",")[0]);
  let productTypeName = productType.split(",")[1];
  let collectionId = parseInt(collection.split(",")[0]);
  let collectionName = collection.split(",")[1];
  let purityId = parseInt(purity.split(",")[0]);
  let purityName = purity.split(",")[1];
  let partyId = parseInt(partyTypeId.split(",")[0]);
  let partyName = partyTypeId.split(",")[1];

  const filteredProducts = productTypeData.filter(
    (product) => product.category_id == categoryId
  );
  const filteredCollection = collectionTypeData.filter(
    (product) => product.productType == productTypeName
  );
  const filteredPurity = purityData.filter(
    (product) => product.category == categoryName
  );
  const filteredBoxes = boxData.filter(
    (product) => product.productName == productTypeName
  );
  const handleStoneName = (value, stoneNumber) => {
    // let stoneName = value.split(",")[0];
    // let stoneRate = value.split(",")[1];
    if (stoneNumber == 1) {
      setStoneName1(value);
      //   setStoneAmount1();
    }
  };
  const [stones, setStones] = useState([
    { stoneName: "", stoneWeight: "", stoneAmount: "" },
  ]);

  const addStone = () => {
    if (stones.length < 4) {
      setStones([
        ...stones,
        { stoneName: "", stoneWeight: "", stoneAmount: "" },
      ]);
    }
  };

  const removeStone = (index) => {
    const updatedStones = [...stones];
    updatedStones.splice(index, 1);
    setStones(updatedStones);

    eval(`setStoneAmount${index + 1}`)(0);
    eval(`setStoneName${index + 1}`)("");
    eval(`setStoneWt${index + 1}`)(0);
  };

  //   const updateStone = (index, field, value) => {
  //     const updatedStones = [...stones];
  //     updatedStones[index][field] = value;
  //     setStones(updatedStones);

  //     // Update corresponding state based on the field and index
  //     switch (field) {
  //       case "stoneAmount":
  //         eval(`setStoneAmount${index + 1}`)(value);
  //         break;
  //       case "stoneName":
  //         eval(`setStoneName${index + 1}`)(value);
  //         break;
  //       case "stoneWeight":
  //         eval(`setStoneWt${index + 1}`)(value);
  //         break;
  //       default:
  //         break;
  //     }
  //   };
  const updateStone = (index, field, value) => {
    const updatedStones = [...stones];
    const updatedStone = { ...updatedStones[index], [field]: value };

    // Update stone amount based on the rate from allStones when the field is "stoneName"
    if (field === "stoneName") {
      const selectedStoneFromAllStones = allStones.find(
        (stone) => stone.stoneName === value
      );
      if (selectedStoneFromAllStones) {
        updatedStone.stoneAmount = selectedStoneFromAllStones.rate;
        eval(`setStoneAmount${index + 1}`)(selectedStoneFromAllStones.rate);
      }
    }

    updatedStones[index] = updatedStone;
    setStones(updatedStones);

    // Update corresponding state based on the field and index
    switch (field) {
      case "stoneAmount":
        eval(`setStoneAmount${index + 1}`)(value);
        break;
      case "stoneName":
        eval(`setStoneName${index + 1}`)(value);
        break;
      case "stoneWeight":
        eval(`setStoneWt${index + 1}`)(value);
        break;
      default:
        break;
    }
  };
  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 >= files.length >= 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };
  const handleFileInputChangeRemove = (indexToRemove) => {
    const newSet = selectedFiles.filter(
      (file, index) => index !== indexToRemove
    );
    setSelectedFiles(newSet);
    if (selectedFiles.length == 1) {
      setSelectedFiles([]);
    }
  };

  //   console.log(stoneName1, "stoneName1");
  //   console.log(stoneAmount1, "stoneAmount1");
  //   console.log(stoneName2, "stoneName2");
  //   console.log(stoneAmount2, "stoneAmount2");
  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);
  console.log(stones, "stones");
  console.log(selectedFiles, "selected Files");
  //   Add bulk stock
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        {showError ? (
          <AlertMessage message={messageToShow} type={messageType} />
        ) : null}
        <AdminBreadCrump
          title={"Add SKU"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"SKU"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 01 */}
                  <RiListUnordered />
                </div>
                <p>All SKU</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 02 */}
                  <RiPlayListAddLine />
                </div>
                <p>Add SKU</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>SKU</th>
                    <th>Category</th>
                    <th>Product Type</th>
                    <th>Collection</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {allSku.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            onClick={() => handleEditClick(x.id)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.id}</td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="stockKeepingUnit"
                            value={editedData.stockKeepingUnit || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.stockKeepingUnit
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="category"
                            value={editedData.category || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.category
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="productType"
                            value={editedData.productType} // Convert to uppercase
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.productType
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="collection"
                            value={editedData.collection} // Convert to uppercase
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.collection
                        )}
                      </td>

                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="description"
                            value={editedData.description || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.description
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Sku</p>
              <form onSubmit={addNewSku}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <div className="adminSkuAddSkuInnerBox">
                    <div className="adminSkuAddSkuInnerUpperItemsBox">
                      <label>SKU</label>
                      <input
                        type="text"
                        value={stockKeepingUnit}
                        onChange={(e) => {
                          setStockKeepingUnit(e.target.value.toUpperCase());
                        }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerUpperItemsBox">
                      {/* <label>Add Image</label> */}
                      {/* <div className="bulkStockAddProductDetailsItem"> */}
                      <label style={{ margin: 0, cursor: "pointer" }}>
                        Images {`${selectedFiles.length}`}
                        <FaImages
                          style={{ margin: "1.2rem", marginInline: "1rem" }}
                          size={"2.5rem"}
                        />
                        <input
                          id="images"
                          style={{ display: "none" }}
                          type="file"
                          onChange={handleFileInputChange}
                          multiple
                        />
                      </label>
                      {/* </div> */}
                    </div>
                    <div className="adminSkuAddSkuInnerUpperItemsBox">
                      <div className="adminSkuAddSkuInnerItemsImagesBox">
                        {selectedFiles.map((file, index) => (
                          <div className="adminSkuAddSkuInnerItemsImagesInnerBox">
                            <img
                              key={index}
                              src={URL.createObjectURL(file)}
                              alt={`Selected Image ${index + 1}`}
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                margin: "5px",
                              }}
                            />
                            <div
                              onClick={() => handleFileInputChangeRemove(index)}
                              className="adminSkuAddSkuInnerItemsImagesBoxCancel"
                            >
                              <RxCross2 strokeWidth={"2px"} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="adminSkuAddSkuInnerUpperItemsBox">
                      <label>Description</label>
                      <textarea
                        type="text"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerUpperItemsBox">
                      <label>Product Remark</label>
                      <textarea
                        type="text"
                        value={productRemark}
                        onChange={(e) => {
                          setProductRemark(e.target.value);
                        }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Category</label>
                      <select
                        id="category"
                        required="required"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">Category</option>
                        {categoriesData.map((x, y) => {
                          return (
                            <option key={y} value={`${x.id},${x.name}`}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Product</label>
                      <select
                        id="productTypeId"
                        required="required"
                        value={productType}
                        onChange={(e) => setProductType(e.target.value)}
                      >
                        <option value="">Product Type</option>
                        {filteredProducts.map((x, y) => {
                          return (
                            <option
                              key={y}
                              value={`${parseInt(x.id)},${x.productTitle}`}
                            >
                              {x.productTitle}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Collection</label>
                      <select
                        id="collection"
                        required="required"
                        value={collection}
                        onChange={(e) => setCollection(e.target.value)}
                      >
                        <option value="">Colletion</option>
                        {filteredCollection.map((x, y) => {
                          return (
                            <option
                              key={y}
                              value={`${parseInt(x.id)},${x.collection_Name}`}
                            >
                              {x.collection_Name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Purity</label>
                      <select
                        id="purity"
                        required="required"
                        value={purity}
                        onChange={(e) => setPurity(e.target.value)}
                      >
                        <option value="">Purity</option>
                        {filteredPurity.map((x, y) => {
                          return (
                            <option
                              key={y}
                              value={`${parseInt(x.id)},${x.label}`}
                            >
                              {x.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Colour</label>
                      <input
                        type="text"
                        value={color}
                        onChange={(e) => {
                          setColor(e.target.value);
                        }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Size</label>
                      <input
                        type="text"
                        value={size}
                        onChange={(e) => {
                          setSize(e.target.value);
                        }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label htmlFor="grosswt">G.Wt</label>
                      <input
                        type="number"
                        id="grosswt"
                        required="required"
                        value={grosswt}
                        onChange={(e) => {
                          setGrosswt(e.target.value),
                            setNetWt(
                              parseFloat(e.target.value) -
                                parseFloat(stoneWeight)
                            );
                        }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label htmlFor="stoneWeight">Total St.Wt</label>

                      <input
                        type="number"
                        id="stoneWeight"
                        value={stoneWeight}
                        onChange={(e) => {
                          e.target.value < parseFloat(grosswt)
                            ? (setStoneWeight(e.target.value),
                              setNetWt(
                                parseFloat(
                                  parseFloat(grosswt) -
                                    parseFloat(e.target.value)
                                ).toFixed(3)
                              ))
                            : null;
                        }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label htmlFor="netWt">Net.Wt</label>

                      <input
                        type="number"
                        id="netWt"
                        value={netWt}
                        readOnly
                        // onChange={(e) => {
                        //   setNetWt(e.target.value),
                        //     setGrosswt(
                        //       parseFloat(e.target.value) +
                        //         parseFloat(stoneWeight)
                        //     );
                        // }}
                      />
                    </div>
                    <p className="adminSkuAddSkuInnerTitles">ADD LABOUR</p>
                    {/* <div className="adminSkuAddSkuInnerUpperItemsBox">s */}
                    <div className="adminSkuAddSkuInnerItemsBox">
                      {/* <label style={{ color: "#02a8b5" }}>Sale Type</label> */}
                      <label>Sale Type</label>
                      {/* <p>Add New Sku</p> */}
                      <select
                        value={saleType}
                        onChange={(e) => setSaleType(e.target.value)}
                      >
                        <option value={"MRP"}>MRP Based</option>
                        <option value={"Weight"}>Weight Based</option>
                      </select>
                    </div>
                    {saleType === "MRP" ? (
                      <div className="adminSkuAddSkuInnerItemsBox">
                        <label>MRP</label>
                        <input
                          type="number"
                          value={mrp}
                          onChange={(e) => {
                            setMRP(e.target.value);
                          }}
                        />
                      </div>
                    ) : (
                      //  null}
                      <>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Making Percentage</label>
                          <input
                            type="number"
                            value={making_Percentage}
                            onChange={(e) => {
                              setMaking_Percentage(e.target.value);
                            }}
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Making Per/Gram</label>
                          <input
                            type="number"
                            value={making_per_gram}
                            onChange={(e) => {
                              setMaking_per_gram(e.target.value);
                            }}
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Making Fixed Amount</label>
                          <input
                            type="number"
                            value={making_Fixed_Amt}
                            onChange={(e) => {
                              setMaking_Fixed_Amt(e.target.value);
                            }}
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Making Fixed Wastage</label>
                          <input
                            type="number"
                            value={making_Fixed_Wastage}
                            onChange={(e) => {
                              setMaking_Fixed_Wastage(e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Stone Pieces</label>
                      <input
                        type="number"
                        value={stonePieces}
                        onChange={(e) => {
                          setStonePieces(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <p className="adminSkuAddSkuInnerTitles">ADD STONE</p>
                  {/* <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Stone Name 1</label>
                      <input
                        type="text"
                        value={stoneName1}
                        onChange={(e) => {
                          handleStoneName(e.target.value, 1);
                        }}
                        list="stonesList"
                      />
                      <datalist id="stonesList">
                        {allStones.map((stone, index) => (
                          <option key={index} value={`${stone.stoneName}`} />
                        ))}
                      </datalist>
                    </div> */}
                  <div className="adminSkuAddSkuInnerItemsStoneBox">
                    {stones.map((stone, index) => (
                      <div className="adminSkuAddSkuInnerItemsStoneInnerBox">
                        <div
                          key={index}
                          className="adminSkuAddSkuInnerItemsBox"
                        >
                          <input
                            type="text"
                            value={stone.stoneName}
                            placeholder={`Stone Name ${index + 1}`}
                            onChange={(e) =>
                              updateStone(index, "stoneName", e.target.value)
                            }
                            list="stonesList"
                          />
                          <datalist id="stonesList">
                            {allStones.map((stone, index) => (
                              <option
                                key={index}
                                value={`${stone.stoneName}`}
                              />
                            ))}
                          </datalist>
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <input
                            type="text"
                            value={stone.stoneWeight}
                            placeholder={`Stone Weight ${index + 1}`}
                            onChange={(e) =>
                              updateStone(index, "stoneWeight", e.target.value)
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <input
                            type="text"
                            value={stone.stoneAmount}
                            placeholder={`Stone Amount ${index + 1}`}
                            onChange={(e) =>
                              updateStone(index, "stoneAmount", e.target.value)
                            }
                          />
                        </div>
                        {/* {stones.length > 1 && ( */}
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <button onClick={() => removeStone(index)}>
                            Remove
                          </button>
                        </div>
                        {/* )} */}
                      </div>
                    ))}
                    {stones.length < 4 ? (
                      <button
                        className="adminSkuAddSkuInnerAddStoneButton"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                        onClick={addStone}
                      >
                        Add Stone
                      </button>
                    ) : null}

                    <div className="adminSkuAddSkuInnerItemsBox">
                      <button style={{ width: "200px" }} type="submit">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
