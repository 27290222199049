import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import {
  a18,
  a20,
  a21,
  a22,
  a23,
  a27,
  a28,
  a36,
  a37,
  a64,
} from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { allStateList } from "../../../Api/StateList";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";
import AlertMessage from "../../../Other Functions/AlertMessage";

export default function AdminAddSupplier() {
  const [active, setActive] = useState("List");
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageToShow, setMessageToShow] = useState("");

  const [partiesData, setPartiesData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [newParty, setNewParty] = useState({
    supplier_code: "",
    supplierType: "",
    supplier_name: "",
    party_pan_no: "",
    party_adhar_no: "",
    //   party_adhar_no: parseInt(aadharNo),
    contact_no: "",
    //   contact_no: parseInt(contactNo),
    email_id: "",
    address: "",
    state: "",
    city: "",
    firm_name: "",
    firm_details: "",
    gst_no: "",
    //   gst_no: parseInt(gstNo),
    central_gst_no: "",
  });
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const fetchAllParties = async () => {
    await fetch(a28)
      .then((res) => res.json())
      .then((data) => setPartiesData(data.data.result));
  };
  console.log(partiesData);

  useEffect(() => {
    fetchAllParties();
  }, []);

  const handleEditClick = (id) => {
    setEditingId(id);
    // Find the item with the selected ID and set its data in the state
    const selectedItem = partiesData.find((x) => x.id === id);
    setEditedData(selectedItem);
  };

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    const formData = {
      ...editedData,
      Id: editingId,
      // Category: editedData.category,
      // Purity: editedData.purity,
      // Label: editedData.label,
      // TodaysRate: editedData.todaysRate,
    };
    try {
      const response = await fetch(a64, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
      } else {
        setMessageType("success");
        setMessageToShow("Updated successfully");
        setShowError(true);
      }
      console.log(data, "updated");
      fetchAllParties();
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };

  const handleNewPartyChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewParty({ ...newParty, [name]: value });
  };
  const addNewParty = async (e) => {
    e.preventDefault();
    const formData = {
      supplier_code: newParty.supplier_code,
      supplierType: newParty.supplierType,
      supplier_name: newParty.supplier_name,
      party_pan_no: newParty.party_pan_no,
      party_adhar_no: newParty.party_adhar_no,
      //   party_adhar_no: parseInt(aadharNo),
      contact_no: newParty.contact_no,
      //   contact_no: parseInt(contactNo),
      email_id: newParty.email_id,
      address: newParty.address,
      state: newParty.state,
      city: newParty.city,
      firm_name: newParty.firm_name,
      firm_details: newParty.firm_details,
      gst_no: newParty.gst_no,
      //   gst_no: parseInt(gstNo),
      central_gst_no: newParty.central_gst_no,
    };
    try {
      const response = await fetch(a27, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      fetchAllParties();
      setActive("List");
      setNewParty({
        supplier_code: "",
        supplierType: "",
        supplier_name: "",
        party_pan_no: "",
        party_adhar_no: "",
        //   party_adhar_no: parseInt(aadharNo),
        contact_no: "",
        //   contact_no: parseInt(contactNo),
        email_id: "",
        address: "",
        state: "",
        city: "",
        firm_name: "",
        firm_details: "",
        gst_no: "",
        //   gst_no: parseInt(gstNo),
        central_gst_no: "",
      });
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
        setActive("AddNew");
      } else {
        setMessageType("success");
        setMessageToShow("Category Added Successfully");
        setShowError(true);
      }
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        {showError ? (
          <AlertMessage message={messageToShow} type={messageType} />
        ) : null}
        <AdminBreadCrump
          title={"Add Supplier"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"Suppliers"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiListUnordered />
                </div>
                <p>All Suppliers</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  <RiPlayListAddLine />
                </div>
                <p>Add Supplier</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>S.Code</th>
                    <th>S.Name</th>
                    <th>S.Type</th>
                    <th>Firm Name</th>
                    <th>Contact No</th>
                    <th>State</th>
                  </tr>
                </thead>
                <tbody>
                  {partiesData.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            onClick={() => handleEditClick(x.id)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.id}</td>
                      <td>{x.supplier_code}</td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="supplier_name"
                            value={editedData.supplier_name || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.supplier_name
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="supplierType"
                            value={editedData.supplierType || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.supplierType
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="firm_name"
                            value={editedData.firm_name || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.firm_name
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="contact_no"
                            value={editedData.contact_no || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.contact_no
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="state"
                            value={editedData.state || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.state
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Supplier</p>
              <form onSubmit={addNewParty}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <label>Supplier Code</label>
                  <input
                    name="supplier_code"
                    value={partiesData.length + 1}
                    readOnly
                    type="text"
                  />
                  <label>Supplier Type</label>
                  <select
                    name="supplierType"
                    value={newParty.supplierType || ""}
                    onChange={handleNewPartyChange}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="Party">Party</option>
                    <option value="Karigar">Karigar</option>
                  </select>
                  <label>Supplier Name</label>
                  <input
                    name="supplier_name"
                    value={newParty.supplier_name}
                    onChange={handleNewPartyChange}
                    type="text"
                    required="required"
                  />
                  <label>Firm Name</label>
                  <input
                    name="firm_name"
                    value={newParty.firm_name}
                    onChange={handleNewPartyChange}
                    type="text"
                    required="required"
                  />
                  <label>Firm Details</label>
                  <input
                    name="firm_details"
                    value={newParty.firm_details}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <label>GST Number</label>
                  <input
                    name="gst_no"
                    value={newParty.gst_no}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <label>Pan Number</label>
                  <input
                    name="party_pan_no"
                    value={newParty.party_pan_no}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <label>Aadhar Number</label>
                  <input
                    name="party_adhar_no"
                    value={newParty.party_adhar_no}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <label>Contact Number</label>
                  <input
                    name="contact_no"
                    value={newParty.contact_no}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <label>Email Address</label>
                  <input
                    name="email_id"
                    value={newParty.email_id}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <label>Address</label>
                  <input
                    name="address"
                    value={newParty.address}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <label>State</label>
                  <select
                    required="required"
                    type="text"
                    name="state"
                    value={newParty.state}
                    onChange={handleNewPartyChange}
                  >
                    <option value="">Select a state</option>
                    {allStateList.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <label>City</label>
                  <input
                    name="city"
                    value={newParty.city}
                    onChange={handleNewPartyChange}
                    type="text"
                  />
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
